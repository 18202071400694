<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>

              <v-btn v-if="can('admin')"
                     class="rounded-lg"
                     color="gifty"
                     text
                     to="/users/settings"
              >
                <v-icon left>mdi-cog</v-icon>
                Configuration
              </v-btn>

              <v-btn
                  v-if="can('admin')"
                  class="rounded-lg mr-1"
                  color="gifty"
                  outlined
                  @click="importExcel"
              >
                <v-icon left>mdi-file-import</v-icon>
                Importer
              </v-btn>

              <v-btn
                  class="rounded-lg"
                  color="gifty"
                  dark
                  depressed
                  @click="addUser"
              >
                <v-icon left>mdi-plus</v-icon>
                Ajouter
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <form @submit.prevent="getUsers">
                    <v-text-field v-model.trim="keyword"
                                  class="search-input"
                                  clearable
                                  dense
                                  filled
                                  hide-details
                                  placeholder="Rechercher..."
                                  rounded
                                  single-line
                                  @click:clear="[keyword = null,getUsers()]"
                    >
                      <template v-slot:append>
                        <v-btn :disabled="!keyword"
                               color="primary"
                               depressed
                               fab
                               type="submit"
                               x-small>
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </form>
                </div>

                <v-spacer/>

                <v-btn class="rounded-lg" color="grey" text @click="openFilter">
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="grey"
                       icon
                       @click="refresh">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="users.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-no-wrap">Compte</th>
                        <th class="text-no-wrap">Nom complet</th>
                        <th class="text-no-wrap">Nom d'utilisateur</th>
                        <th v-if="can('admin | support')" class="text-no-wrap text-center">Compte POS</th>
                        <th class="text-no-wrap">Email</th>
                        <th class="text-no-wrap">Téléphone</th>
                        <th class="text-no-wrap">Rôle</th>
                        <th class="text-no-wrap">Date d'inscription</th>
                        <th class="text-no-wrap text-center">Version</th>
                        <th class="text-no-wrap">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, i) in users" :key="i">

                        <td>
                          <v-switch v-model="item.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    dense
                                    hide-details
                                    readonly
                                    @click="block(item.id)"></v-switch>
                        </td>

                        <td class="font-weight-medium d-flex align-center text-no-wrap">
                          <v-avatar size="35">
                            <v-img
                                v-if="item.image"
                                :src="fileUrl + item.image"
                            />
                            <v-img
                                v-else
                                :src="require('@/assets/avatar.png')"
                            />
                          </v-avatar>
                          &nbsp;
                          {{ item.name }}
                        </td>

                        <td class="text-no-wrap">{{ item.username }}</td>

                        <td v-if="can('admin | support')" class="text-center">
                          <div v-if="item.salepoint" class="d-flex align-center">
                            <v-btn color="info"
                                   class="text-none v-btn--active"
                                   rounded
                                   text
                                   small
                                   @click="$refs.salepointFormDialog.open(item)">
                              {{ item.salepoint.pos_name }}
                              <v-icon right>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-tooltip color="info" top>
                              <template v-slot:activator="{ on }">
                                <v-btn color="info"
                                       icon
                                       small
                                       @click="$refs.salepointFormDialog.open(item)"
                                       v-on="on">
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Ajouter un compte POS</span>
                            </v-tooltip>
                          </div>
                        </td>

                        <td class="text-no-wrap">
                          <a :href="'mailto:'+item.email"> {{ item.email }}</a>
                        </td>
                        <td class="text-no-wrap">
                          {{ item.phone }}
                          <v-tooltip v-if="can('support')" bottom color="gifty">
                            <template v-slot:activator="{ on }">
                              <v-btn color="gifty" icon small
                                     @click="$refs.updatePhoneDialog.open(item)"
                                     v-on="on">
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Modifier</span>
                          </v-tooltip>

                        </td>
                        <td class="text-no-wrap">
                          <v-btn class="text-none" color="grey" text>
                            <v-icon color="primary" left>mdi-lock</v-icon>
                            {{ item.role.name }}
                          </v-btn>
                        </td>

                        <td class="text-no-wrap">

                          {{ item.created_at }}
                        </td>
                        <td class="text-no-wrap text-center">

                          {{ item.version }}
                        </td>

                        <td class="text-no-wrap">
                          <div class="d-flex">

                            <v-tooltip v-if="can('admin | supplier_manager | support')" bottom color="gifty">
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="update(item)" v-on="on">
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Modifier</span>
                            </v-tooltip>

                            <v-tooltip bottom color="gifty"  v-if="can('support | admin')">
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="remove(item.id)" v-on="on">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                            <v-tooltip v-if="can('support | admin')" bottom color="gifty">
                              <template v-slot:activator="{ on }">
                                <v-btn icon
                                       @click="$refs.updatePasswordDialog.open(item)"
                                       v-on="on">
                                  <v-icon>mdi-pencil-lock-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Modifier le mot de passe</span>
                            </v-tooltip>

                            <v-tooltip bottom color="gifty" v-if="can('support | admin')">
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="showAccount(item)" v-on="on">
                                  <v-icon>mdi-finance</v-icon>
                                </v-btn>
                              </template>
                              <span>Détails</span>
                            </v-tooltip>

                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getUsers"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">
                  <div :style="{ width: '120px' }">
                    <v-select
                        v-model="perPage"
                        :items="[10, 20, 30, 50, 100]"
                        dense
                        hide-details
                        label="Ligne par page"
                        outlined
                    ></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination
                      v-if="total > 10"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="8"
                      @input="getUsers"
                  ></v-pagination>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <FormDialog ref="formDialog" @refresh="getUsers"/>
      <ImportDialog ref="importDialog" @refresh="getUsers"/>
      <FilterDialog ref="filterDialog" @filter="filterBy($event)"/>
      <UpdatePasswordDialog ref="updatePasswordDialog"/>
      <UpdatePhoneDialog ref="updatePhoneDialog"
                         @save="users.find(el=>el.id === $event.user_id).phone = $event.phone"/>

      <SalepointFormDialog ref="salepointFormDialog" @refresh="refresh"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FormDialog from "./FormDialog";
import SalepointFormDialog from "./SalepointFormDialog";
import ImportDialog from "./ImportDialog";
import FilterDialog from "./FilterDialog.vue"
import UpdatePasswordDialog from "./UpdatePasswordDialog.vue"
import UpdatePhoneDialog from "./UpdatePhoneDialog.vue"

export default {
  components: {
    FormDialog,
    SalepointFormDialog,
    ImportDialog,
    NoResults,
    FilterDialog,
    UpdatePasswordDialog,
    UpdatePhoneDialog
  },
  data() {
    return {
      id: null,
      fileUrl: process.env.VUE_APP_FILE_URL,
      isLoading: false,
      overlay: false,
      isBlock: false,

      users: [],
      filter: {},
      keyword: "",
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    getUsers() {
      this.isLoading = true;
      HTTP.get("/v1/users?page=" + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword,
          ...this.filter, //  todo filter
        },
      })
          .then((res) => {
            this.isLoading = false;
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            this.isLoading = false;
            this.users = res.data.data.data;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
    },
    addUser() {
      this.$refs.formDialog.open();
    },
    importExcel() {
      this.$refs.importDialog.open();
    },
    update(item) {
      this.$refs.formDialog.open(item);
    },
    block(id) {
      this.id = id;
      this.isBlock = true;
      this.$confirm_dialog = true;
      this.$confirm_dialog_body = "Êtes vous sûr de vouloir changer le statut de  cet utilisateur";
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
      this.$confirm_dialog_body = "Êtes vous sûr de vouloir supprimer cet utilisateur";
    },
    deleteItem() {
      this.overlay = true;
      let data = {
        id: this.id,
      };
      HTTP.post("/v1/users/delete", data)
          .then(() => {
            this.overlay = false;
            let i = this.users.findIndex((el) => el.id === this.id);
            this.$delete(this.users, i);
            this.id = null;
            this.$successMessage = "Cet utilisateur a été supprimé avec succès.";
          })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },
    changeStatus() {
      this.overlay = true;
      let data = {
        id: this.id,
      };
      HTTP.post("/v1/users/change-status", data)
          .then(() => {
            this.overlay = false;
            this.isBlock = false;
            this.id = null;
            this.$successMessage = "Le statut de Cet utilisateur a été modifié avec succès.";
            this.getUsers()
          })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },
    showAccount(user) {
      let route = this.$router.resolve({path: "/users/" + user.account.id});
      window.open(route.href, "_blank");
    },
    refresh() {
      this.filter = {};
      this.getUsers();
    },

    openFilter() {
      this.$refs.filterDialog.open();
    },
    filterBy(data) {
      this.filter = {
        page: 1,
        ...this.filter,
        ...data
      };
      this.getUsers();
    }
  },
  created() {
    this.getUsers();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        if (this.isBlock) {
          this.changeStatus();
        } else {
          this.deleteItem();
        }
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.getUsers();
    },
  },
};
</script>

<style>
.search-input.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner {
  margin-top: 5px;
  margin-bottom: 5px
}

.search-input .v-input__icon--clear {
  margin-top: 5px
}
</style>
